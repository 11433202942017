import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IStudent } from '../pages/contents/StudentManagement';
import { useFirebaseAuth } from '../services/FirebaseAuthContext';
import { useClassrooms } from '../services/FirestoreHooks';
import { ClassroomSelector } from '../components/ClassroomSelector';
import { NotificationSnackbar } from '../pages/contents/NotificationSnackbar';
import { isSuccess } from '../jasmine-common-lib/utils/FailableResult';

interface EditStudentDialogProps {
  student?: IStudent;
  handleClose: () => void;
  onFinish: () => void;
  open: boolean;
}

export function EditStudentDialog(props: EditStudentDialogProps) {
  const { student, handleClose, onFinish, open } = props;
  console.log(`student: ${JSON.stringify(student)}`);
  const { t } = useTranslation();
  const { educationInstitutionUser, firebaseInstance } = useFirebaseAuth();
  const { classrooms } = useClassrooms(
    educationInstitutionUser?.educationInstitution.id
  );
  const validationSchema = yup.object({
    active: yup.boolean().required(),
    classrooms: yup.array(
      yup.object().shape({
        id: yup.string().required(),
        schoolYear: yup.object().shape({
          id: yup.string().required(),
        }),
      })
    ),
  });
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>();
  const formik = useFormik({
    initialValues: student
      ? {
          active: student.active,
          classrooms: student.classrooms,
        }
      : {
          active: true,
          classrooms: [],
        },
    onSubmit: async (values, { setSubmitting }) => {
      console.log(`onSubmit called with values: ${JSON.stringify(values)}`);
      setSnackbarMessage(t('Updating the Student'));
      try {
        const result = await firebaseInstance.updateStudent(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          educationInstitutionUser!.educationInstitution.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          student!.email,
          values
        );
        if (isSuccess(result)) {
          setSnackbarMessage(t('The Student has been successfully updated'));
          await new Promise((r) => setTimeout(r, 1000));
          onFinish();
        } else {
          setSnackbarMessage(t('Failed to update the Student'));
        }
      } catch (e) {
        console.log(e);
        setSnackbarMessage(t('Failed to update the Student'));
      } finally {
        setSubmitting(false);
      }
      setSubmitting(false);
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  if (!student) {
    return null;
  }
  const onClose = () => {
    formik.resetForm();
    handleClose();
  };
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>{t('Editing a Student')}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ display: 'block', margin: '16px 8px' }}>
            <Box
              component="section"
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box component="div" sx={{ margin: '8px', width: '200px' }}>
                  {t('Name of the Student')}
                </Box>
                <Box>{student.displayName}</Box>
              </Box>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box component="div" sx={{ margin: '8px', width: '200px' }}>
                  {t('Email')}
                </Box>
                <Box>{student.email}</Box>
              </Box>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box component="div" sx={{ margin: '8px', width: '200px' }}>
                  {t('Status')}
                </Box>
                <Select
                  name="active"
                  labelId="active"
                  id="activeInput"
                  value={formik.values.active}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.active)}
                  displayEmpty
                >
                  <MenuItem
                    value={
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                      true as any
                    }
                  >
                    {t('Active')}
                  </MenuItem>
                  <MenuItem
                    value={
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                      false as any
                    }
                  >
                    {t('Inactive')}
                  </MenuItem>
                </Select>
              </Box>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box component="div" sx={{ margin: '8px', width: '200px' }}>
                  {t('Classrooms')}
                </Box>
                <ClassroomSelector
                  sx={{ m: 1, width: '500px' }}
                  id="classrooms"
                  value={formik.values.classrooms}
                  options={classrooms}
                  onChange={(_e, value) => {
                    void formik.setFieldValue('classrooms', value);
                  }}
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              type="submit"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              disabled={!formik.dirty || !formik.isValid}
            >
              {t('Save the changes')}
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              onClick={() => {
                onClose();
              }}
            >
              {t('Close')}
            </Button>
          </FormControl>
        </form>
      </Dialog>
      <NotificationSnackbar message={snackbarMessage} />
    </>
  );
}
