import { ChangeEvent, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Input,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReactMultiEmail } from 'react-multi-email';
import { isSuccess } from '../jasmine-common-lib/utils/FailableResult';
import { useFirebaseAuth } from '../services/FirebaseAuthContext';
import { useClassrooms } from '../services/FirestoreHooks';
import { ClassroomSelector } from '../components/ClassroomSelector';
import { NotificationSnackbar } from '../pages/contents/NotificationSnackbar';
import 'react-multi-email/dist/style.css';
import { IClassroom } from '../services/firebase/Firebase.ts';

export type AddStudentsMode = 'NEW' | 'EXISTING';

interface AddStudentsDialogProps {
  initialClassroomId: string | undefined;
  handleClose: () => void;
  onFinish: () => void;
  open: boolean;
  mode: AddStudentsMode;
  emails: string[];
}

export function AddStudentsDialog(props: AddStudentsDialogProps) {
  const { handleClose, onFinish, open, mode, emails, initialClassroomId } =
    props;

  const inputFileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { educationInstitutionUser, firebaseInstance } = useFirebaseAuth();

  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>();

  const { classrooms } = useClassrooms(
    educationInstitutionUser?.educationInstitution.id
  );

  const validationSchema = yup.object().shape({
    emails: yup.array().of(yup.string().email(t('Enter a valid email'))),
    classrooms: yup.array(
      yup.object().shape({
        id: yup.string().required(),
        schoolYear: yup.object().shape({
          id: yup.string().required(),
        }),
      })
    ),
  });

  const emailValidator = yup.string().email().required();

  const formik = useFormik({
    initialValues: {
      emails: [],
      classrooms:
        initialClassroomId === undefined
          ? ([] as IClassroom[])
          : classrooms.filter((c) => c.id === initialClassroomId),
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (mode === 'NEW') {
        setSnackbarMessage(t('Adding the Students'));
      } else {
        setSnackbarMessage(t('Adding the Students to classes'));
      }
      try {
        const result = await firebaseInstance.addStudents(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          educationInstitutionUser!.educationInstitution.id,
          {
            emails: emails.length === 0 ? values.emails : emails,
            classrooms: values.classrooms,
          }
        );
        if (isSuccess(result)) {
          setSnackbarMessage(t('The Students have been successfully added'));
          await new Promise((r) => setTimeout(r, 1000));
          onFinish();
        } else {
          setSnackbarMessage(t('Failed to add the Students'));
        }
      } catch (e) {
        setSnackbarMessage(t('Failed to add the Students'));
        console.log(e);
      } finally {
        setSubmitting(false);
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const fileReader = new FileReader();
    if (!e.target.files) {
      return;
    }
    fileReader.readAsText(e.target.files[0]);
    fileReader.onload = (ev) => {
      if (!ev.target) {
        return;
      }
      const contents = ev.target.result;
      if (!contents) {
        return;
      }
      const emails: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      for (const candidate of contents.toString().split('\n')) {
        try {
          const result = emailValidator.validateSync(candidate);
          emails.push(result);
        } catch (error) {
          console.info(
            `Value is ignored because it does not look like an email address:'${candidate}'.`
          );
        }
      }
      // Remove dup
      const emailsWithoutDup = Array.from(new Set(emails));
      void formik.setFieldValue('emails', emailsWithoutDup);
    };
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
        {mode === 'NEW' ? (
          <DialogTitle>{t('Adding Students')}</DialogTitle>
        ) : (
          <DialogTitle>
            {t('AddingStudentsToClassesTitle', { num: emails.length })}
          </DialogTitle>
        )}
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ display: 'block', margin: '16px 8px' }}>
            <Box
              component="section"
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {mode === 'NEW' ? (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Box component="div" sx={{ flex: 1 }}>
                    {t('Email address of the students')}
                  </Box>
                  <Box sx={{ flex: 2 }}>
                    <ReactMultiEmail
                      placeholder={t('Email address')}
                      emails={formik.values.emails}
                      onChange={(email: string[]) => {
                        void formik.setFieldValue('emails', email);
                      }}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span
                              data-tag-handle
                              onClick={() => {
                                removeEmail(index);
                              }}
                            >
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </Box>
                </Box>
              ) : null}
              <Box
                component="div"
                sx={{
                  marginTop: '8px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ flex: 1 }}>{t('Classrooms')}</Box>
                <ClassroomSelector
                  sx={{ flex: 2 }}
                  id="classrooms"
                  value={formik.values.classrooms}
                  options={classrooms}
                  onChange={(_e, value) => {
                    void formik.setFieldValue('classrooms', value);
                  }}
                />
              </Box>
            </Box>
            <Box
              component="div"
              sx={{
                marginTop: '8px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ margin: '16px 8px', textTransform: 'none' }}
                disabled={!formik.dirty || !formik.isValid}
              >
                {t('Add Students')}
              </Button>
              <Button
                variant="contained"
                sx={{ margin: '16px 8px', textTransform: 'none' }}
                onClick={() => {
                  onClose();
                }}
              >
                {t('Close')}
              </Button>
              {mode === 'NEW' ? (
                <>
                  <Button
                    variant="contained"
                    sx={{ margin: '16px 8px', textTransform: 'none' }}
                    onClick={() => {
                      if (!inputFileRef.current) {
                        return;
                      }
                      // Clear the selected file to allow the same file to be selected again.
                      inputFileRef.current.value = '';
                      inputFileRef.current.click();
                    }}
                  >
                    {t('Select a file')}
                  </Button>
                  <Input
                    inputRef={inputFileRef}
                    type="file"
                    id="fileInput"
                    onChange={onSelectFile}
                    sx={{ display: 'none' }}
                  ></Input>
                </>
              ) : null}
            </Box>
          </FormControl>
        </form>
      </Dialog>
      <NotificationSnackbar message={snackbarMessage} />
    </>
  );
}
