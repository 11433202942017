import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridRowSelectionModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFirebaseAuth } from '../../services/FirebaseAuthContext';
import {
  useClassrooms,
  useEducationInstitutionUserRegistrations,
  useSchoolYears,
} from '../../services/FirestoreHooks';
import { IEducationInstitutionUserRegistration } from '../../services/firebase/Firebase';
import { Loader } from './Loader';
import { IClassroom } from '../../services/firebase/Firebase';
import { EditStudentDialog } from '../../dialogs/EditStudentDialog';
import {
  AddStudentsDialog,
  AddStudentsMode,
} from '../../dialogs/AddStudentsDialog';

interface StudentManagementState {
  schoolYearId: string;
  classroomId: string;
}

export interface IStudent {
  displayName: string;
  email: string;
  active: boolean;
  classrooms: IClassroom[];
}

function getStudents(
  targetEmails: string[],
  allClassrooms: IClassroom[],
  educationInstitutionUserRegistrations: IEducationInstitutionUserRegistration[]
) {
  const ret: IStudent[] = [];
  for (const email of targetEmails) {
    const classrooms: IClassroom[] = allClassrooms.filter((classroom) => {
      return classroom.emails.includes(email);
    });
    const reg = educationInstitutionUserRegistrations.find((registration) => {
      return registration.email === email;
    });
    if (reg && reg.userType === 'student') {
      const sorted = classrooms.sort((a: IClassroom, b: IClassroom) =>
        `${a.schoolYear.name}&nbsp;${a.name}`.localeCompare(
          `${b.schoolYear.name}&nbsp;${b.name}`
        )
      );
      ret.push({
        displayName: reg.displayName || '',
        active: reg.active,
        email: email,
        classrooms: sorted,
      });
    }
  }
  return ret;
}

export function StudentManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { educationInstitutionUser } = useFirebaseAuth();

  const [addStudentsMode, setAddStudentsMode] =
    useState<AddStudentsMode>('NEW');
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [addStudentsDialogOpen, setAddStudentsDialogOpen] = useState(false);
  const [editStudentDialogOpen, setEditStudentDialogOpen] = useState(false);
  const [targetStudent, setTargetStudent] = useState<IStudent | undefined>();

  const onAddStudentsDialogClose = useCallback(() => {
    setAddStudentsMode('NEW');
    setAddStudentsDialogOpen(false);
  }, []);
  const onAddStudentsDialogFinish = useCallback(() => {
    setAddStudentsMode('NEW');
    setAddStudentsDialogOpen(false);
    refreshRegistrations();
    refresh();
  }, []);

  const onRowSelectionModelChange = useCallback(
    (value: GridRowSelectionModel) => {
      setSelectedEmails(value as string[]);
    },
    []
  );

  const onEditStudentDialogClose = useCallback(() => {
    setTargetStudent(undefined);
    setEditStudentDialogOpen(false);
  }, []);

  const { schoolYears, isSchoolYearsLoading } = useSchoolYears(
    educationInstitutionUser?.educationInstitution.id
  );

  const {
    educationInstitutionUserRegistrations,
    isEducationInstitutionUserRegistrationsLoading,
    refreshRegistrations,
  } = useEducationInstitutionUserRegistrations(
    educationInstitutionUser?.educationInstitution.id
  );

  const schoolYearId: string | undefined =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (location.state as StudentManagementState)?.schoolYearId;

  // Always fetch all the classrooms because we'll need to know
  // which classrooms each students belong to.
  const { classrooms, isClassroomsLoading, refresh } = useClassrooms(
    educationInstitutionUser?.educationInstitution.id
  );

  const classroomId: string | undefined =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (location.state as StudentManagementState)?.classroomId;

  const schoolYearSelectorOptions = [
    { name: '----------', id: '', isCurrentSchoolYear: false },
    ...schoolYears.map((schoolYear) => {
      return {
        name: schoolYear.name,
        id: schoolYear.id,
        isCurrentSchoolYear: schoolYear.isCurrentSchoolYear,
      };
    }),
  ];

  const classroomsForSelector = schoolYearId
    ? classrooms.filter((classroom) => classroom.schoolYear.id === schoolYearId)
    : classrooms;

  const classroomSelectorOptions = [
    { name: '----------', id: '' },
    ...classroomsForSelector.map((classroom) => {
      return {
        name: schoolYearId
          ? classroom.name
          : `${classroom.schoolYear.name} ${classroom.name}`,
        id: classroom.id,
      };
    }),
  ];

  const targetClassrooms: IClassroom[] =
    isSchoolYearsLoading || isClassroomsLoading
      ? []
      : classroomId &&
          classrooms.find((classroom) => {
            return classroom.id === classroomId;
          })
        ? ([
            classrooms.find((classroom) => {
              return classroom.id === classroomId;
            }),
          ] as IClassroom[])
        : classroomsForSelector;

  const targetEmails: string[] = [];
  if (!schoolYearId) {
    // Show all the students who added to the institution.
    for (const reg of educationInstitutionUserRegistrations) {
      targetEmails.push(reg.email);
    }
  } else {
    for (const targetClassroom of targetClassrooms) {
      for (const targetEmail of targetClassroom.emails) {
        if (!targetEmails.includes(targetEmail)) {
          targetEmails.push(targetEmail);
        }
      }
    }
  }

  const onEditStudentDialogFinish = useCallback(() => {
    setTargetStudent(undefined);
    setEditStudentDialogOpen(false);
    refreshRegistrations();
    refresh();
  }, []);

  const students = getStudents(
    targetEmails,
    classrooms,
    educationInstitutionUserRegistrations
  );

  const rows: GridValidRowModel[] = students.map((student) => {
    return {
      ...student,
      id: student.email,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: t('Name'),
      flex: 1,
      sortable: true,
      filterable: true,
      hideable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              verticalAlign: 'middle',
              justifyContent: 'space-evenly',
              height: '100%',
            }}
          >
            {(params.row as IStudent).displayName}
          </Box>
        );
      },
    },
    {
      field: 'email',
      headerName: t('Email'),
      flex: 1,
      sortable: true,
      filterable: true,
      hideable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              verticalAlign: 'middle',
              justifyContent: 'space-evenly',
              height: '100%',
            }}
          >
            {(params.row as IStudent).email}
          </Box>
        );
      },
    },
    {
      field: 'classrooms',
      headerName: t('Classrooms'),
      flex: 1,
      sortable: true,
      filterable: true,
      hideable: false,
      valueGetter: (val: IClassroom[]) => {
        return val.reduce((accumulator, cur) => {
          const maybeSpace = accumulator === '' ? '' : ' ';
          return `${accumulator}${maybeSpace}${cur.schoolYear.name} ${cur.name}`;
        }, '');
      },
      renderCell: (params) => {
        return (
          <Box
            sx={{
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              verticalAlign: 'middle',
              justifyContent: 'space-evenly',
              height: '100%',
            }}
          >
            {(params.row as IStudent).classrooms.map(
              (classroom: IClassroom) => {
                return (
                  <Box key={classroom.id}>
                    {classroom.schoolYear.name}&nbsp;{classroom.name}
                  </Box>
                );
              }
            )}
          </Box>
        );
      },
    },
    {
      field: 'active',
      headerName: t('Status'),
      flex: 1,
      sortable: true,
      filterable: true,
      hideable: false,
      valueGetter: (value: boolean): string => {
        return value ? t('Active') : t('Inactive');
      },
      renderCell: (params) => {
        return (
          <Box
            sx={{
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              verticalAlign: 'middle',
              justifyContent: 'space-evenly',
              height: '100%',
            }}
          >
            {(params.row as IStudent).active ? t('Active') : t('Inactive')}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      sortable: false,
      filterable: false,
      hideable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              verticalAlign: 'middle',
              justifyContent: 'space-evenly',
              height: '100%',
            }}
          >
            <Box>
              <Button
                sx={{
                  marginRight: '5px',
                  marginLeft: '5px',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  setTargetStudent(params.row as IStudent);
                  setEditStudentDialogOpen(true);
                }}
              >
                {t('Edit Student')}
              </Button>
            </Box>
          </Box>
        );
      },
    },
  ];

  if (
    isSchoolYearsLoading ||
    isClassroomsLoading ||
    isEducationInstitutionUserRegistrationsLoading
  ) {
    return <Loader />;
  }
  return (
    <>
      <Typography variant="h5">{t('Manage Students')}</Typography>
      <Divider sx={{ margin: '16px 4px' }} />
      {schoolYears.length > 0 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box sx={{ margin: '8px' }}>{t('School Year')}</Box>
              <Select
                sx={{
                  width: '200px',
                }}
                name="targetSchoolYear"
                labelId="targetSchoolYear"
                id="targetSchoolYearInput"
                value={schoolYearId || ''}
                displayEmpty
                onChange={(event) => {
                  navigate('/students', {
                    state: {
                      schoolYearId: event.target.value,
                      classroomId: undefined,
                    },
                  });
                }}
              >
                {schoolYearSelectorOptions.map((option) => {
                  return (
                    <MenuItem
                      value={option.id}
                      selected={option.id === schoolYearId}
                      key={option.id}
                    >
                      {option.name + (option.isCurrentSchoolYear ? '★' : '')}
                    </MenuItem>
                  );
                })}
              </Select>
              <Box sx={{ margin: '8px' }}>{t('Classroom')}</Box>
              <Select
                sx={{
                  width: '350px',
                }}
                name="targetClassroom"
                labelId="targetClassroom"
                id="targetClassroomInput"
                value={classroomId || ''}
                displayEmpty
                onChange={(event) => {
                  navigate('/students', {
                    state: {
                      schoolYearId:
                        classrooms.find((classroom) => {
                          return classroom.id === event.target.value;
                        })?.schoolYear.id ?? schoolYearId,
                      classroomId: event.target.value,
                    },
                  });
                }}
              >
                {classroomSelectorOptions.map((option) => {
                  return (
                    <MenuItem
                      value={option.id}
                      selected={option.id === classroomId}
                      key={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  sx={{
                    marginRight: '5px',
                    marginLeft: '30px',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddStudentsDialogOpen(true);
                  }}
                >
                  {t('Add Students')}
                </Button>
                <Button
                  sx={{
                    marginRight: '5px',
                    marginLeft: '5px',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  disabled={selectedEmails.length === 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddStudentsMode('EXISTING');
                    setAddStudentsDialogOpen(true);
                  }}
                >
                  {t('Add selected Students to classes')}
                </Button>
              </Box>
            </Box>
            <Stack
              minHeight={0}
              height={1}
              sx={{ height: 'calc(100% - 160px)' }}
            >
              <DataGrid
                sx={{
                  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                    {
                      outline: 'none',
                    },
                  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                    {
                      outline: 'none',
                    },
                  width: `100%`,
                  marginTop: '20px',
                }}
                checkboxSelection
                rows={rows}
                columns={columns}
                onRowSelectionModelChange={onRowSelectionModelChange}
                getRowHeight={() => 'auto'}
                disableRowSelectionOnClick={true}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                disableColumnSelector={true}
              />
            </Stack>
          </Box>
          <EditStudentDialog
            open={editStudentDialogOpen}
            student={targetStudent}
            handleClose={onEditStudentDialogClose}
            onFinish={onEditStudentDialogFinish}
          ></EditStudentDialog>
          <AddStudentsDialog
            open={addStudentsDialogOpen}
            initialClassroomId={classroomId}
            handleClose={onAddStudentsDialogClose}
            onFinish={onAddStudentsDialogFinish}
            mode={addStudentsMode}
            emails={selectedEmails}
          ></AddStudentsDialog>
        </>
      ) : (
        <Box sx={{ marginTop: '20px' }}>
          {t(
            'There is no school year. If you create a school year, you can manage classrooms for each school year separately.'
          )}
        </Box>
      )}
    </>
  );
}
