import { SyntheticEvent } from 'react';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { IClassroom } from '../services/firebase/Firebase';

interface ClassroomSelectorProps {
  id: string;
  value: IClassroom[];
  options: IClassroom[];
  onChange: (e: SyntheticEvent, value: IClassroom[]) => void;
  sx?: SxProps<Theme>;
}

function getFullName(classroom: IClassroom): string {
  return `${classroom.schoolYear.name} ${classroom.name}`;
}

export function ClassroomSelector(props: ClassroomSelectorProps) {
  const { id, value, options, onChange, sx } = props;
  return (
    <Autocomplete<IClassroom, true>
      id={id}
      value={value}
      sx={sx}
      multiple={true}
      isOptionEqualToValue={(option, value) => {
        return (
          option.schoolYear.id === value.schoolYear.id && option.id === value.id
        );
      }}
      options={options}
      getOptionLabel={(option) => getFullName(option)}
      disableCloseOnSelect
      onChange={(e, value) => {
        onChange(e, value);
      }}
      renderInput={(params: object) => (
        <TextField {...params} variant="outlined" />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <MenuItem
            {...props}
            key={getFullName(option)}
            value={getFullName(option)}
            sx={{ justifyContent: 'space-between' }}
          >
            {getFullName(option)}
            {selected ? <CheckIcon color="info" /> : null}
          </MenuItem>
        );
      }}
    />
  );
}
