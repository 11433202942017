import { useEffect, useState } from 'react';

import { useFirebaseAuth } from './FirebaseAuthContext';
import {
  IClassroom,
  IEducationInstitutionUserRegistration,
  IEducationInstitutionUserType,
  ISchoolYear,
} from './firebase/Firebase';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  Timestamp,
} from 'firebase/firestore';

export const useLegacyClassroomSchoolYears = (
  educationInstitutionId?: string
) => {
  const [legacyClassroomSchoolYears, setLegacyClassroomSchoolYears] = useState<
    number[]
  >([]);
  const { firebaseInstance } = useFirebaseAuth();
  const runQuery = async () => {
    const legacyClassroomPath = collection(
      firebaseInstance.getFirestore(),
      'education_institutions',
      'v1',
      'profiles',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      educationInstitutionId!,
      'classrooms'
    );
    const result = await getDocs(legacyClassroomPath);
    for (const doc of result.docs) {
      if (doc.data().schoolYear) {
        setLegacyClassroomSchoolYears((cur) => {
          if (!cur.includes(doc.data().schoolYear as number)) {
            cur.push(doc.data().schoolYear as number);
          }
          return cur;
        });
      }
    }
  };
  useEffect(() => {
    setLegacyClassroomSchoolYears([]);
    if (educationInstitutionId) {
      void runQuery();
    }
  }, [educationInstitutionId]);

  return legacyClassroomSchoolYears;
};

export const useEducationInstitutionUserRegistrations = (
  educationInstitutionId?: string
) => {
  const { firebaseInstance } = useFirebaseAuth();
  const [
    educationInstitutionUserRegistrations,
    setEducationInstitutionUserRegistrations,
  ] = useState<IEducationInstitutionUserRegistration[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const runQuery = async () => {
    if (educationInstitutionId === undefined) {
      setIsLoading(false);
      return;
    }
    const registrationPath = collection(
      firebaseInstance.getFirestore(),
      'education_institutions',
      'v1',
      'profiles',
      educationInstitutionId,
      'education_institution_users'
    );
    const q = query(registrationPath, orderBy('createdAt'));
    const qs = await getDocs(q);
    for (const doc of qs.docs) {
      setEducationInstitutionUserRegistrations((current) => {
        return [
          ...current,
          {
            id: doc.id,
            email: doc.data().email as string,
            displayName: (doc.data().displayName || '') as string,
            userType: doc.data().userType as IEducationInstitutionUserType,
            active:
              doc.data().active !== undefined
                ? (doc.data().active as boolean)
                : true,
            createdAt: (doc.data().createdAt as Timestamp).toDate(),
          },
        ];
      });
    }
    setIsLoading(false);
  };

  const refresh = (): void => {
    if (educationInstitutionId) {
      setIsLoading(true);
      setEducationInstitutionUserRegistrations([]);
      void runQuery();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setEducationInstitutionUserRegistrations([]);
    void runQuery();
  }, [educationInstitutionId]);

  return {
    educationInstitutionUserRegistrations:
      educationInstitutionUserRegistrations,
    refreshRegistrations: refresh,
    isEducationInstitutionUserRegistrationsLoading: isLoading,
  };
};

export const useSchoolYears = (educationInstitutionId?: string) => {
  const { firebaseInstance, educationInstitutionUser } = useFirebaseAuth();
  const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const runQuery = async () => {
    if (educationInstitutionId === undefined) {
      setIsLoading(false);
      return;
    }
    const schoolYearsPath = collection(
      firebaseInstance.getFirestore(),
      'education_institutions',
      'v1',
      'profiles',
      educationInstitutionId,
      'school_years'
    );
    const q = query(schoolYearsPath, orderBy('endDate'));
    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      const classroomPath = collection(schoolYearsPath, doc.id, 'classrooms');
      const classroomQuery = query(classroomPath);
      const count = (await getDocs(classroomQuery)).docs.length;
      setSchoolYears((current) => {
        return [
          ...current,
          {
            id: doc.id,
            educationInstitutionId: doc.data().educationInstitutionId as string,
            name: doc.data().name as string,
            endDate: (doc.data().endDate as Timestamp).toDate(),
            numberOfClassRooms: count,
            isCurrentSchoolYear:
              doc.id ===
              educationInstitutionUser?.educationInstitution
                .currentSchoolYearId,
            createdAt: (doc.data().createdAt as Timestamp).toDate(),
            updatedAt: (doc.data().updatedAt as Timestamp).toDate(),
          },
        ];
      });
    }
    setIsLoading(false);
  };

  const refresh = (): void => {
    if (educationInstitutionId) {
      setIsLoading(true);
      setSchoolYears([]);
      void runQuery();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setSchoolYears([]);
    void runQuery();
  }, [educationInstitutionId]);

  return {
    schoolYears: schoolYears,
    refresh: refresh,
    isSchoolYearsLoading: isLoading,
  };
};

export const useClassrooms = (
  educationInstitutionId?: string,
  schoolYearId?: string
) => {
  const { firebaseInstance, educationInstitutionUser } = useFirebaseAuth();
  const [classrooms, setClassrooms] = useState<IClassroom[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const runQuery = async () => {
    if (!educationInstitutionId) {
      setIsLoading(false);
      return;
    }
    const schoolYears: ISchoolYear[] = [];
    if (schoolYearId) {
      const d = await getDoc(
        doc(
          firebaseInstance.getFirestore(),
          'education_institutions',
          'v1',
          'profiles',
          educationInstitutionId,
          'school_years',
          schoolYearId
        )
      );
      if (!d.exists()) {
        // In this case, just return and provide the initial state.
        setIsLoading(false);
        return;
      }
      schoolYears.push({
        id: d.id,
        educationInstitutionId: d.data().educationInstitutionId as string,
        name: d.data().name as string,
        endDate: (d.data().endDate as Timestamp).toDate(),
        numberOfClassRooms: 0,
        isCurrentSchoolYear:
          d.id ===
          educationInstitutionUser?.educationInstitution.currentSchoolYearId,
        createdAt: (d.data().createdAt as Timestamp).toDate(),
        updatedAt: (d.data().updatedAt as Timestamp).toDate(),
      });
    } else {
      const schoolYearPath = collection(
        firebaseInstance.getFirestore(),
        'education_institutions',
        'v1',
        'profiles',
        educationInstitutionId,
        'school_years'
      );
      const qs = await getDocs(query(schoolYearPath));
      for (const doc of qs.docs) {
        schoolYears.push({
          id: doc.id,
          educationInstitutionId: doc.data().educationInstitutionId as string,
          name: doc.data().name as string,
          endDate: (doc.data().endDate as Timestamp).toDate(),
          numberOfClassRooms: 0,
          isCurrentSchoolYear:
            doc.id ===
            educationInstitutionUser?.educationInstitution.currentSchoolYearId,
          createdAt: (doc.data().createdAt as Timestamp).toDate(),
          updatedAt: (doc.data().updatedAt as Timestamp).toDate(),
        });
      }
    }
    const classrooms: IClassroom[] = [];
    for (const schoolYear of schoolYears) {
      const queryPath = collection(
        firebaseInstance.getFirestore(),
        'education_institutions',
        'v1',
        'profiles',
        educationInstitutionId,
        'school_years',
        schoolYear.id,
        'classrooms'
      );
      const q = query(queryPath, orderBy('createdAt'));
      const querySnapshot = await getDocs(q);
      for (const doc of querySnapshot.docs) {
        classrooms.push({
          id: doc.id,
          educationInstitutionId: doc.data().educationInstitutionId as string,
          schoolYear: {
            ...schoolYear,
            numberOfClassRooms: querySnapshot.docs.length,
          },
          name: doc.data().name as string,
          emails: doc.data().emails as string[],
          createdAt: (doc.data().createdAt as Timestamp).toDate(),
          updatedAt: (doc.data().updatedAt as Timestamp).toDate(),
        });
      }
    }
    setClassrooms((_current) => {
      return classrooms;
    });
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setClassrooms([]);
    void runQuery();
  }, [educationInstitutionId, schoolYearId]);

  const refresh = (): void => {
    if (educationInstitutionId) {
      setIsLoading(true);
      setClassrooms([]);
      void runQuery();
    }
  };

  return {
    classrooms: classrooms,
    refresh: refresh,
    isClassroomsLoading: isLoading,
  };
};
